<template>
    <app-layout title="Manage Users">
        <div class="loader" v-if="loading"></div>
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'Manage Users']"/>
        </template>

        <Card color="gray-500" title="Manage Users">
            <div class="buttons">
                <div>
                    <button
                        @click="setActiveView('ManageUsersTable')"
                        :class="buttonClasses('ManageUsersTable')"
                    >Manage Users</button>

                    <button
                        @click="setActiveView('PendingInvitationsTable')"
                        :class="buttonClasses('PendingInvitationsTable')"
                    >Pending Invitations</button>
                </div>

                <div>
                    <template v-if="$page.props.user.role_type == 'AD'">
                        <button
                            v-show="activeView !== 'PendingInvitationsTable'"
                            @click="deleteUsers"
                            :class="buttonClasses()"
                        >Delete Users</button>

                        <button
                            v-show="activeView == 'PendingInvitationsTable'"
                            @click="deleteUserInvitations"
                            :class="buttonClasses()"
                        >Delete Invitations</button>
                    </template>

                    <Link
                        :href="route('users.import')"
                        :class="buttonClasses()"
                    >Import Users</Link>

                    <button
                        @click="openQIModal()"
                        :class="buttonClasses()"
                    >Quick Invite</button>

                    <Link
                        :href="route('search.invite')"
                        :class="buttonClasses()"
                    >Search &amp; Invite</Link>

                    <button
                        @click="openMFAModal()"
                        :class="buttonClasses()" v-if="this.$page.props.user.role_type == 'AD'"
                    >Two-Factor Auth</button>
                </div>
            </div>

            <SimpleModal :visible="showQuickInviteModal" @on-close="closeQIModal()">
                <div v-if="validate_msg != ''">
                    <p class="text-red-500 my-0"><strong> {{validate_msg}}</strong></p>
                </div>
                <form ref="form" @submit.prevent="submitQuickInviteForm">
                    <h5 class="text-blue-500 mb-3">Quick Invite </h5>
                    <div>
                        <JetLabel for="email" value="Email" />
                        <JetInput id="email" type="email" class="mt-1 block w-full" v-model="quickInviteForm.email" required />
                        <!-- <div v-if="$page.errors.Email" class="text-red-500">{{ $page.errors.Email[0] }}</div> -->
                    </div>

                    <!--<div class="mb-4">
                        <button wire:click.prevent="store()" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5" v-show="!editMode" @click="validateUser(form)">
                            Validate User
                        </button>
                    </div>-->

                    <div class="mt-4">
                        <JetLabel for="first_name" value="First Name" />
                        <JetInput id="first_name" type="text" class="mt-1 block w-full" v-model="quickInviteForm.first_name" required />
                        <!-- <div v-if="$page.errors.name" class="text-red-500">{{ $page.errors.name[0] }}</div> -->
                    </div>

                    <div class="mt-4">
                        <JetLabel for="last_name" value="Last Name" />
                        <JetInput id="last_name" type="text" class="mt-1 block w-full" v-model="quickInviteForm.last_name" required />
                        <!--<div v-if="$page.errors.last_name" class="text-red-500">{{ $page.errors.name[0] }}</div>-->
                    </div>

                    <div class="mt-4">
                        <SimpleSelect label="Role Type" name="role_type" v-model="quickInviteForm.role_type" required :options="user_types" />
                        <!--<div v-if="$page.errors.Mobile" class="text-red-500">{{ $page.errors.Mobile[0] }}</div>-->
                    </div>

                    <div class="mt-4">
                        <JetLabel for="account_clearance" value="Account Clearance"/>
                        <jet-checkbox name="account_clearance" id="account_clearance" v-model:checked="quickInviteForm.account_clearance" />
                    </div>

                    <!--<div class="mb-4">
                        <label for="exampleFormControlInput2" class="block text-gray-700 text-sm font-bold mb-2">Password:</label>
                        <input type="text" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="exampleFormControlInput2" v-model="quickInviteForm.password" placeholder="Enter Password">
                        <div v-if="$page.errors.password" class="text-red-500">{{ $page.errors.password }}</div>
                    </div>-->

                    <div class="flex mt-3 -mx-1 -mb-1">
                        <button
                            v-if="editMode"
                            type="button"
                            class="btn-green m-1"
                            @click.prevent="updateQIForm(quickInviteForm)"
                        >Update</button>

                        <button
                            v-else
                            type="submit"
                            class="btn-green m-1"
                        >Send Invitation</button>

                        <button
                            type="button"
                            class="btn-outline-gray m-1"
                            @click.prevent="closeQIModal()"
                        >Cancel</button>

                        <button
                            type="button"
                            class="btn-outline-gray m-1"
                            @click.prevent="resetQIForm()"
                        >Reset</button>
                    </div>
                </form>
            </SimpleModal>

            <SimpleModal :visible="showMFAModal" @on-close="closeMFAModal()">
                <h5 class="mb-4">Two-Factor Authentication</h5>
                <!--<div class="flex flex-wrap -mx-2 -mt-2" :class="{'mb-2': !mfaModified}">
                    <p class="text-blue-500 text-xs m-2"><span class="font-bold">Current Start:</span> {{ formatDate(currentMfaStartDate) }}</p>
                    <p class="text-blue-500 text-xs m-2"><span class="font-bold">Current End:</span> {{ formatDate(currentMfaEndDate) }}</p>
                </div>
                <div class="flex flex-wrap -mx-2 -mt-2 mb-2" v-if="mfaModified">
                    <p class="text-green-500 text-xs m-2"><span class="font-bold">New Start:</span> {{ formatDate(mfaForm.mfa_start_date) }}</p>
                    <p class="text-green-500 text-xs m-2"><span class="font-bold">New End:</span> {{ formatDate(mfaForm.mfa_end_date) }}</p>
                </div>-->
                <form ref="form" @submit.prevent="submitMfaForm">
                    <div class="btn-checkboxes">
                        <label class="btn-outline-gray" :class="{active: mfaForm.mfa_enabled == 'Yes'}">
                            <JetInput type="radio" name="mfa_enabled" v-model="mfaForm.mfa_enabled" value="Yes" />
                            Enabled
                        </label>

                        <label class="btn-outline-gray" :class="{active: mfaForm.mfa_enabled == 'No'}">
                            <JetInput type="radio" name="mfa_enabled" v-model="mfaForm.mfa_enabled" value="No" />
                            Disabled
                        </label>
                    </div>

                    <!--<SimpleSelect name="duration" :modelValue="this.$page.props.user.mfa_global_duration" :options="durationOptions" v-model="mfaForm.duration" />-->
                    <jet-label for="duration">Duration between MFA requests (in days)</jet-label>
                    <JetInput name="duration" type="number" min="0" max="99" class="mt-1 block w-full" placeholder="Enter Duration In Days" v-model="mfaForm.duration" required/>

                    <jet-label for="ttl">TTL of MFA code (in minutes)</jet-label>
                    <JetInput name="ttl" type="number" min="0" max="99" class="mt-1 block w-full" placeholder="Enter TTL of MFA Code In Minutes" v-model="mfaForm.mfa_ttl" required/>

                    <div class="flex flex-wrap -mx-2 -mb-2 mt-2">
                        <button class="btn-blue m-2">Submit</button>
                        <button class="btn-outline-gray m-2" @click.prevent="closeMFAModal()">Cancel</button>
                    </div>
                </form>
            </SimpleModal>

            <ManageUsersTable
                v-if="activeView == 'ManageUsersTable'"
                :cols="4"
                :data="users_data"
                :searchable-data="['first_name', 'last_name', 'role_type', 'email']"
                @edit="editQIForm($event)"
                @delete-row="deleteUser($event)"
                @impersonate="impersonate($event)"
                @exitimpersonate="exitimpersonate($event)"
                @restore-user="restoreUser($event)"
            />

            <PendingInvitationsTable
                v-if="activeView == 'PendingInvitationsTable'"
                :cols="5"
                :data="pending_invitations"
                :searchable-data="['first_name', 'last_name', 'role_type', 'email']"
                @delete-invitation="deleteUserInvitation($event)"
                @fetch-accepted-invitations="fetchAcceptedInvitations()"
                @fetch-user-invitations="fetchUserInvitations()"
            />
        </Card>
    </app-layout>
</template>



<script>
    import PendingInvitationsTable from './Tables/PendingInvitationsTable.vue'
    import SimpleSelect from '@/Components/FormElements/SimpleSelect.vue'
    import ManageUsersTable from './Tables/ManageUsersTable.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import formProcessingMixin from '@/Mixins/formProcessingMixin.js'
    import SimpleModal from '@/Components/SimpleModal.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import Card from '@/Components/Card.vue'
    import moment from 'moment'

    export default {
        mixins: [formattingMixin,formProcessingMixin],
        components: {
            PendingInvitationsTable,
            ManageUsersTable,
            Breadcrumbs,
            SimpleModal,
            SimpleSelect,
            AppLayout,
            JetLabel,
            JetInput,
            JetCheckbox,
            Card,
            Link,
        },
        props: ['data', 'errors'],
        data() {
            return {
                activeView: 'ManageUsersTable',
                showQuickInviteModal: false,
                showMFAModal: false,
                editMode: false,
                currentMfaStartDate: '2021-12-10', // Pull in dynamically
                currentMfaEndDate: '2021-12-10', // Pull in dynamically
                quickInviteForm: {
                    first_name: null,
                    email: null,
                    role_type: null,
                    last_name: null,
                    account_clearance: null,
                },
                mfaForm: {
                    mfa_enabled: 'No', // Pull in dynamically
                    duration: this.$page.props.user.mfa_global_duration,
                    mfa_start_date: '', // Pull initially from current
                    mfa_end_date: '', // Then change when the form fields are modified
                    mfa_ttl: this.$page.props.user.mfa_global_ttl,
                },
                durationOptions: [
                    {name: '', value: '', selected: true, hidden: 'true'},
                    {name: '30 days', value: 30},
                    {name: '60 days', value: 60},
                    {name: '90 days', value: 90},
                ],
                pending_invitations: [],
                users_data:[],
                validate_msg: null,
                error_msg: null,
                loading:false,
            }
        },
        methods: {
            setActiveView(view) {
                this.activeView = view;
            },
            buttonClasses(view) {
                return `btn-gray${view && this.activeView == view ? ' active' : ''}`;
            },
            openQIModal() {
                this.showQuickInviteModal = true;
            },
            closeQIModal() {
                this.resetQIForm();
                this.showQuickInviteModal = false;
            },
            openMFAModal() {
                this.showMFAModal = true;
            },
            closeMFAModal() {
                this.mfaForm.mfa_start_date = this.currentMfaStartDate;
                this.mfaForm.mfa_end_date = this.currentMfaEndDate;
                this.showMFAModal = false;
            },
            resetQIForm() {
                // In order to keep data reactive you need to set individual properties
                // Straight up replacing an entire object will make the properties no longer reactive and break any current reactivity
                this.quickInviteForm.first_name = null;
                this.quickInviteForm.email = null;
                this.quickInviteForm.role_type = null;
                this.quickInviteForm.last_name = null;
                this.quickInviteForm.account_clearance = null;
            },
            saveQIForm(data) {
                let vm = this;
                this.loading = true,
                this.$inertia.post('/users', data, {
                    preserveScroll: true,
                    onFinish: () => {
                        vm.loading = false
                        vm.resetQIForm();
                        vm.closeQIModal();
                        vm.editMode = false;
                        vm.fetchUserInvitations();
                    },
                })
            },
            submitQuickInviteForm(e) {
                let vm = this;
                vm.loading = true;
                axios
                    .post(route('submitQuickInviteForm'), vm.quickInviteForm)
                    .then(res => {
                        vm.loading = false;
                        vm.validate_msg = false;
                        vm.resetQIForm();
                        vm.closeQIModal();
                        vm.editMode = false;
                        vm.fetchUserInvitations();
                    })
                    .catch(function (error) {
                        vm.loading = false;
                        vm.validate_msg = error.response.data.errors.email[0]
                        //alert(error.response.data.errors.email);
                    });
            },
            editQIForm(data) {
                this.quickInviteForm = Object.assign({}, data);
                this.editMode = true;
                this.openQIModal();
            },
            updateQIForm(data) {
                data._method = 'PUT';
                axios
                    .post('/users/' + data.id, data)
                    .then(res => {
                        this.fetchUserInvitations();
                        this.fetchUsers();
                        this.closeQIModal();
                    });
            },
            validateUser(data) {
                let vm = this;
                axios
                    .post(route('validateUser'), data)
                    .then(res => {
                        vm.validate_msg = res.data.message;
                        vm.quickInviteForm.first_name = res.data.first_name
                        vm.quickInviteForm.last_name = res.data.last_name
                        vm.quickInviteForm.role_type = res.data.role_type
                        vm.quickInviteForm.account_clearance = res.data.account_clearance
                    })
                    .catch(function (error) {
                        //existingObj.validate_msg = Object.values(error.response.data.errors.email).flat().join();
                        alert(error.response.data.errors.email);
                    });
            },
            deleteUser(data) {
                if (!confirm('Are you sure want to remove?')) return;
                let vm = this;
                this.loading = true;
                axios.get(route('delete.user',data.id))
                    .then(response => {
                        vm.$page.props.jetstream.flash.banner = response.data.message;
                        vm.loading =  false,
                        vm.fetchUsers();
                    })
            },
            restoreUser(data) {
                if (!confirm('Are you sure want to restore this user?')) return;
                let vm = this;
                this.loading = true;
                axios.get(route('restore.user',data.id))
                    .then(response => {
                        vm.$page.props.jetstream.flash.banner = response.data.message;
                        vm.loading =  false,
                        vm.fetchUsers();
                    })
            },
            fetchUserInvitations() {
                let vm = this;
                axios
                    .get(route('pending_invitations'))
                    .then(response => {
                        vm.pending_invitations.splice(0,vm.pending_invitations.length);
                        response.data.forEach(invitation => {
                            vm.pending_invitations.push(invitation);
                        })

                    })
            },
            fetchAcceptedInvitations() {
                let vm = this;
                axios
                    .get(route('accepted_invitations'))
                    .then(response => {
                        vm.pending_invitations.splice(0,vm.pending_invitations.length);
                        response.data.forEach(invitation => {
                            vm.pending_invitations.push(invitation);
                        })

                    })
            },
            fetchUsers() {
                axios
                    .get(route('fetch_users'))
                    .then(response => {
                        this.users_data = response.data;
                    })
            },
            deleteUsers() {
              if (confirm("Do you really want to delete all users?")){
                let vm = this;
                axios
                    .get(route('delete.users'))
                    .then(response => {
                        vm.$page.props.jetstream.flash.banner = response.data.message;
                        vm.loading =  false,
                        vm.fetchUsers();
                    })
                }
            },
            deleteUserInvitations() {
                if (confirm("Do you really want to delete all user invitations?")) {
                    let vm = this;
                    axios
                        .get(route('delete.invitations'))
                        .then(response => {
                            vm.$page.props.jetstream.flash.banner = response.data.message;
                            vm.loading =  false,
                            vm.fetchUserInvitations();
                        })
                }
            },
            deleteUserInvitation(row) {
                if (confirm(`Do you really want to delete ${row.first_name}'s invitation?`)) {
                    let vm = this;
                    axios
                        .get(`/delete_invitation/${row.id}`)
                        .then(response => {
                            vm.$page.props.jetstream.flash.banner = response.data.message;
                            vm.loading =  false,
                            vm.fetchUserInvitations();
                        })
                }
            },
            impersonate(data) {
                let vm = this;
                this.loading = true;
                axios
                    .post(route('impersonation'),data)
                    .then(response => {
                        vm.loading = false;
                        vm.isImpersonating = true;
                        vm.$inertia.visit(route('dashboard'));
                    })
            },
            exitimpersonate(data) {
                let vm = this;
                axios
                    .post(route('exitimpersonation'),data)
                    .then(response => {
                        vm.$inertia.visit(route('dashboard'));
                    })
            },
            updateMfaStartEndDates(duration) {
                let now = moment();
                this.mfaForm.mfa_start_date = now.format();
                this.mfaForm.mfa_end_date = now.add(Number(duration), 'days').format();
            },
            submitMfaForm(e) {
                let vm = this;
                this.loading = true
                this.$inertia.post('/user/mfa-authentication', this.mfaForm, {
                    preserveScroll: true,
                    onFinish: (response) => {
                        vm.loading = false
                        vm.closeMFAModal();
                        vm.fetchUsers();
                    },
                })
            }
        },
        computed: {
            mfaEnabled() {
                return this.mfaForm.mfa_enabled;
            },
            mfaDuration() {
                return this.mfaForm.duration;
            },
            mfaModified() {
                let [cs, ns, ce, ne] = [
                    this.currentMfaStartDate,
                    this.mfaForm.mfa_start_date,
                    this.currentMfaEndDate,
                    this.mfaForm.mfa_end_date
                ].map(d => this.formatDate(d));

                return !((cs==ns)&&(ce==ne));
            },
            user_types() {
                if(this.$page.props.user.role_type == 'AD') {
                    return [
                            { name: "", value: "", hidden: true },
                            { name: "CN", value: "CN" },
                            { name: "BR", value: "BR" },
                            { name: "EM", value: "EM" },
                            { name: "AD", value: "AD" }
                        ]
                } else if(this.$page.props.user.role_type == 'EM') {
                    return [
                            { name: "", value: "", hidden: true },
                            { name: "CN", value: "CN" },
                            { name: "BR", value: "BR" },
                    ]
                } else {
                    return [
                            { name: "", value: "", hidden: true },
                    ]
                }
            }
        },
        watch: {
            mfaEnabled(n) {
                if (n == 'No') this.mfaForm.duration = 0;
            },
            mfaDuration(n) {
                this.mfaForm.mfa_start_date = this.currentMfaStartDate
                this.mfaForm.mfa_end_date = this.currentMfaEndDate
                if (n && this.mfaEnabled == 'Yes') this.updateMfaStartEndDates(n);
            }
        },
        mounted() {
            this.fetchUserInvitations();
            this.fetchUsers();
            this.mfaForm.mfa_enabled = this.$page.props.user.mfa_global_is_enabled?'Yes':'No';
            this.mfaForm.duration = this.$page.props.user.mfa_global_duration;
            this.mfaForm.mfa_start_date = this.currentMfaStartDate;
            this.mfaForm.mfa_end_date = this.currentMfaEndDate;
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .buttons {
        @include bp-up($bp-lg + 1) { margin: 0 -.5rem 1rem; }
        @include bp-down($bp-lg) {
            margin: 0 -.25rem 1rem;
            flex-wrap: wrap;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            @include bp-down($bp-lg) {
                flex-wrap: wrap;
                flex-grow: 1;
            }
            display: flex;

            button, a {
                text-align: center;
                @include bp-up($bp-lg + 1) { margin: .5rem; }
                @include bp-down($bp-lg) { margin: .25rem; }
                @include bp-between(451px, $bp-lg) { flex: 1 0 calc(50% - .5rem) }
                @include bp-down(450px) { flex: 1 0 calc(100% - .5rem) }
            }
        }
    }

    .btn-checkboxes {
        display: flex;
        flex-wrap: wrap;
        margin: -.5rem -.5rem 0;

        input {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        label {
            margin: .5rem;
            text-align: center;
        }
    }
</style>
