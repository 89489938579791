<template>
    <form enctype="multipart/form-data" ref="form" @submit.prevent="submitForm">
       <div class="loader" v-if="loading"></div>
        <template v-else>
            <h3 class="text-blue-500">Additional Certificate Holder</h3>
            <div v-if="validate_msg != ''" class="bg-green-100 p-2">
                <p class="text-green-800 my-0">{{validate_msg}}</p>
            </div>
            <div v-if="error_msg" class="bg-red-100 p-2">
                <p class="text-red-800 my-0">{{error_msg}}</p>
            </div>

            <div class="md:flex -mb-2 -mx-2">
                <div class="m-2 flex-grow">
                    <JetLabel for="requester_name" value="Requester Full Name" />
                    <JetInput id="requester_name" type="text" class="mt-1 block w-full" name="requester_name" v-model="form.requester_name" required/>
                </div>

                <div class="m-2 flex-grow">
                    <JetLabel for="requester_email" value="Requester Email" />
                    <JetInput id="requester_email" type="email" class="mt-1 block w-full" name="requester_email" v-model="form.requester_email" required/>
                </div>

                <div class="m-2 flex-grow">
                    <SimpleDate v-model="form.date" label="Effective Date of Change" name="effective_date_of_change" required/>
                </div>
            </div>

            <div class="md:flex -mb-2 -mx-2">
                <div class="flex-grow">
                    <div class="m-2">
                        <JetLabel for="cert_holder_full_name" value="Certificate Holder Full Name" />
                        <JetInput id="cert_holder_full_name" type="text" name="cert_holder_full_name" class="mt-1 block w-full" required/>
                    </div> 
                </div>
            </div>

            <AddressInputGroup  whose-address="Certificate Holder"/>

            <div class="md:flex -mb-2 -mx-2">
                <div class="m-2 flex-grow flex flex-col">
                    <JetLabel for="notes" value="Notes" />
                    <textarea id="notes" name="notes" class="flex-grow border-gray-400 focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 shadow-sm bg-white mt-1 block w-full" placeholder="Enter Message"></textarea>
                </div>
            </div>

            <div class="md:flex -mb-2 -mx-2">
                <div class="m-2 flex-grow">
                    <JetLabel for="cdocument" value="Upload Document" />
                    <SimpleFileUploader id="cdocument" v-model="form.files" />
                </div>
            </div>

            <div class="flex flex-wrap mt-2 -mx-2 -mb-2">
                <button type="submit" class="btn-green m-2">Submit</button>
                <button @click.prevent="closeModal()" class="btn-outline-gray m-2">Cancel</button>
                <button @click.prevent="reset()" class="btn-outline-gray m-2">Reset</button>
            </div>
        </template>
    </form>
</template>

<script>
import SimpleFileUploader from '@/Components/FormElements/SimpleFileUploader.vue'
import AddressInputGroup from '@/Components/AddressInputGroup.vue'
import SimpleDate from '@/Components/FormElements/SimpleDate.vue'
import formProcessingMixin from '@/Mixins/formProcessingMixin.js'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'

export default {
    mixins: [formProcessingMixin],
    props: ["pdata", "resetFormTrigger", "visible"],
    components: {
        SimpleFileUploader,
        AddressInputGroup,
        SimpleDate,
        JetLabel,
        JetInput,
    },
    data() {
        return {
            // just for passing user data by default, not for using with submission
            form: {
                requester_name: '',
                requester_email: '',
                effective_date_of_change: '',
                files: []
            },
            validate_msg: '',
            error_msg: '',
            loading: false,
        }
    },
    methods: {
        submitForm(event) {
            this.submit(event, 'submitAdditionalCertificateHolderForm', this.additionalData, {name: 'files', list: this.form.files});
        },
        initWithUserData() {
            let u = this.$page.props.user;
            this.form.requester_name = `${u.first_name} ${u.last_name}`;
            this.form.requester_email = u.email;
        }
    },
    computed: {
        additionalData() {
            return [
                { key: 'account_manager_email', value: this.pdata.account_manager_email },
                { key: 'account_manager_name',  value: this.pdata.account_manager_name },
                { key: 'insured_name',          value: this.pdata.insured_name }
            ]
        }
    },
    watch: {
        resetFormTrigger() {
            this.reset();
        },
        visible(n) {
            if (n) this.initWithUserData();
        }
    }
}
</script>