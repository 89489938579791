<template>
    <app-layout title="Manage Customers">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'Manage Customers']"/>
        </template>
        <Card color="gray-500" title="Manage Customers">

            <Teleport to="body">
                <SimpleModal :visible="showClaims" @on-close="closeClaimsModal()" width="min(1300px, 100%)" >
                    <ClaimsSection
                        v-if="currentCustomer"
                        :entity-id="currentCustomer"
                    />
                </SimpleModal>
            </Teleport>

            <Teleport to="body">
                <SimpleModal :visible="showPolicies" @on-close="closePoliciesModal()" width="min(1300px, 100%)" >
                    <PoliciesSection
                        v-if="currentCustomer"
                        :entity-id="currentCustomer"
                    />
                </SimpleModal>
            </Teleport>

            <div class="loader" v-if="loading"></div>

            <ManageCustomersTable
                v-if="activeView == 'ManageCustomersTable'"
                :cols="3"
                :data="customer_data"
                :searchable-data="['entity_name', 'address']"
                @impersonate="impersonate($event)"
                @exitimpersonate="exitimpersonate($event)"
                @showclaims="showclaims($event)"
                @showpolicies="showpolicies($event)"
            />
        </Card>
    </app-layout>
</template>



<script>
    import SimpleSelect from '@/Components/FormElements/SimpleSelect.vue'
    import ManageCustomersTable from './Tables/ManageCustomersTable.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import formProcessingMixin from '@/Mixins/formProcessingMixin.js'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import SimpleModal from '@/Components/SimpleModal.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import Card from '@/Components/Card.vue'
    import ClaimsSection from '../Policies/Sections/ClaimsSection.vue'
    import PoliciesSection from '../Policies/Sections/PoliciesSection.vue'
    import moment from 'moment'

    export default {
        mixins: [formattingMixin,formProcessingMixin],
        components: {
            ManageCustomersTable,
            Breadcrumbs,
            SimpleModal,
            SimpleSelect,
            AppLayout,
            JetLabel,
            JetInput,
            Card,
            Link,
            ClaimsSection,
            PoliciesSection,
        },
        data() {
            return {
                activeView: 'ManageCustomersTable',
                currentCustomer: 0,
                showClaims: false,
                showPolicies: false,
                editMode: false,
                currentMfaStartDate: '2021-12-10', // Pull in dynamically
                currentMfaEndDate: '2021-12-10', // Pull in dynamically
                quickInviteForm: {
                    first_name: null,
                    email: null,
                    role_type: null,
                    last_name: null,
                },

                durationOptions: [
                    {name: '', value: '', selected: true, hidden: 'true'},
                    {name: '30 days', value: 30},
                    {name: '60 days', value: 60},
                    {name: '90 days', value: 90},
                ],
                customer_data:[],
                validate_msg: null,
                error_msg: null,
                loading:false,
                user_types: [
                    { name: "", value: "", hidden: true },
                    { name: "CN", value: "CN" },
                    { name: "BR", value: "BR" },
                    { name: "EM", value: "EM" }
                ],
            }
        },
        methods: {
            setActiveView(view) {
                this.activeView = view;
            },
            buttonClasses(view) {
                return `btn-gray${view && this.activeView == view ? ' active' : ''}`;
            },
            fetchCustomerList() {
                this.loading = true,
                axios
                    .get(route('get_broker_customer_list'))
                    .then(response => {
                        this.loading = false,
                        this.customer_data = response.data.search_results;
                    })
            },

        },
        mounted() {
            this.fetchCustomerList();
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .buttons {
        @include bp-up($bp-lg + 1) { margin: 0 -.5rem 1rem; }
        @include bp-down($bp-lg) {
            margin: 0 -.25rem 1rem;
            flex-wrap: wrap;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            @include bp-down($bp-lg) {
                flex-wrap: wrap;
                flex-grow: 1;
            }
            display: flex;

            button, a {
                text-align: center;
                @include bp-up($bp-lg + 1) { margin: .5rem; }
                @include bp-down($bp-lg) { margin: .25rem; }
                @include bp-between(451px, $bp-lg) { flex: 1 0 calc(50% - .5rem) }
                @include bp-down(450px) { flex: 1 0 calc(100% - .5rem) }
            }
        }
    }

    .btn-checkboxes {
        display: flex;
        flex-wrap: wrap;
        margin: -.5rem -.5rem 0;

        input {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        label {
            margin: .5rem;
            text-align: center;
        }
    }
</style>
