<template>
    <div class="claim-details-wrappper text-gray-800" v-if="data">
        <div class="flex flex-wrap items-start">
            <div class="lg:w-2/3">
                <h4 class="font-medium mb-2">Claim Details: {{ getData('Claim_Number') }}</h4>
                <div class="claim-details mb-4">
                    <span class="a1 font-medium pr-4">Claimant:</span>
                    <span class="a2">{{ getData('Prin_Claimant_Name') }}</span>
                    <span class="b1 font-medium pr-4">Loss Description:</span>
                    <span class="b2">{{ getData('Loss_Short_Desc') }}</span>
                    <span class="c1 font-medium pr-4">Policy Number:</span>
                    <span class="c2"><Link class="underline" :href="route('policies.details', getData('Contract_ID'))">{{ getData('policy_number') }}</Link></span>
                    <span class="d1 font-medium pr-4">Effective Date:</span>
                    <span class="d2">{{ formatDate(getData('Eff_Date')) }}</span>
                    <span class="e1 font-medium pr-4">Expiration Date:</span>
                    <span class="e2">{{ formatDate(getData('Expiration_Date')) }}</span>
                    <span class="f1 font-medium pr-4">Reporting Date:</span>
                    <span class="f2">{{getData('Rep_Book_Date')}}</span>
                    <span class="g1 font-medium pr-4">Close Date:</span>
                    <span class="g2"> {{getData('Closed_Date')}}</span>
                    <span class="h1 font-medium pr-4">Status:</span>
                    <span class="h2"><Status :status="getData('Claim_Status')" /></span>
                    <span class="i1 font-medium pr-4">Indemnity Reserve:</span>
                    <span class="i2">{{ formatMoney(getData('Tot_Avail_Reserve')) }}</span>
                    <span class="j1 font-medium pr-4">Expense Reserve:</span>
                    <span class="j2">{{ formatMoney(getData('Tot_Avail_Exp_Reserve')) }}</span>
                    <span class="k1 font-medium pr-4">Indemnity Payments:</span>
                    <span class="k2">{{ formatMoney(getData('Tot_Pay_Made')) }}</span>
                    <span class="l1 font-medium pr-4">Expense Payments:</span>
                    <span class="l2">{{ formatMoney(getData('Tot_Exp_Pay_Made')) }}</span>
                    <span class="m1 font-medium pr-4">Deductible:</span>
                    <span class="m2">{{ formatMoney(getData('Tot_Ded')) }}</span>
                    <span class="n1 font-medium pr-4">Total Incurred:</span>
                    <span class="n2">{{ formatMoney(getData('Tot_Incurred')) }}</span>
                </div>
            </div>

            <div class="lg:w-1/3 flex flex-col items-stretch justify-between">
                <div class="p-4 bg-gray-300">
                    <h4 class="font-bold text-blue-500 mb-4 -mt-2">Your Claim Specialist</h4>
                    <div class="flex flex-wrap -m-2">
                        <div class="p-2" style="flex:1 0 120px">
                            <img
                                class="w-full"
                                :src="getData('specialist_image')"
                                alt="Image of NAME OF ACCOUNT MANAGER"
                            /> <!-- needs to be hooked up -->
                        </div>
                        <div class="p-2 text-gray-500" style="flex:9999 0 auto">
                            <h6 class="leading-tight mb-1 font-bold"> {{ getData('specialist_name') }}</h6>
                            <div class="pl-2 text-sm">
                                <p class="phone-grid">
                                    <span class="font-bold">Office:</span>
                                    <span class="ml-4"><a :href="'tel:'+String(getData('specialist_phone')).replace(/\D/g,'')">{{ getData('specialist_phone') }}</a></span> <!-- needs to be hooked up -->
                                    <span class="font-bold">Fax:</span>
                                    <span class="ml-4">{{ getData('specialist_fax') }}</span>
                                </p>
                                <p class="cursor-pointer underline"><a :href="'mailto:'+String(getData('specialist_email'))">{{ getData('specialist_email') }}</a></p> <!-- this may end up needing to pop that contact modal -->
                            </div>
                        </div>
                    </div>
                </div>
                <form enctype="multipart/form-data" ref="form" @submit.prevent="submitForm">
                    <div class="-mx-2 mt-2 -mb-2 flex flex-wrap items-end">
                        <div v-if="validate_msg != ''" class="bg-green-100 p-2">
                            <p class="text-green-500 my-0"> {{validate_msg}}</p>
                        </div>
                        <div v-if="error_msg != ''" class="bg-red-100 p-2">
                            <p class="text-white-500 my-0">{{error_msg}}</p>
                        </div>
                        <div class="m-2 flex-grow">
                            <!--<p><strong>Default upload files limit is {{getData('default_upload_limit')}}</strong></p>-->
                            <SimpleFileUploader v-model="form.files" />
                        </div>
                        <button class="m-2 mb-4 flex-grow btn-blue" :disabled="!form.files.length">Upload Documents</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleFileUploader from '@/Components/FormElements/SimpleFileUploader.vue'
import formProcessingMixin from '@/Mixins/formProcessingMixin.js'
import formattingMixin from '@/Mixins/formattingMixin.js'
import { Link } from '@inertiajs/inertia-vue3'
import JetLabel from '@/Jetstream/Label.vue'
import Status from '@/Components/Status.vue'

export default {
    mixins: [formattingMixin,formProcessingMixin],
    props: ["data"],
    components: {
        SimpleFileUploader,
        JetLabel,
        Status,
        Link,
    },
    data() {
        return {
            form: {
                files: [],
            },
            validate_msg: '',
            error_msg: '',
            loading: false,
        }
    },
    methods: {
        getData(prop) {
            return this.data?.[prop] || null;
        },
        submitForm(event) {
            this.submit(event, 'uploadClaimDocuments', this.additionalData, {name: 'files', list: this.form.files});
        }
    },
    computed: {
        additionalData() {
            return [
                { key: 'claimant',      value: this.data.Prin_Claimant_Name },
                { key: 'insured_name',  value: this.data.insured_name },
                { key: 'claim_id',      value: this.data.Claim_ID },
                { key: 'claim_number',  value: this.data.Claim_Number },
                { key: 'policy_number', value: this.data.policy_number }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../scss/_mixins.scss';

    .claim-details {
        @include fluid-size(-4px, -16px, margin-left);
        @include fluid-size(-4px, -16px, margin-right);
        display: grid;

        @include bp-up($bp-sm + 1) {
            grid-template:
                "a1 a2 . i1 i2" max-content
                "b1 b2 . j1 j2" max-content
                "c1 c2 . k1 k2" max-content
                "d1 d2 . l1 l2" max-content
                "e1 e2 . m1 m2" max-content
                "f1 f2 . n1 n2" max-content
                "g1 g2 . o1 o2" max-content
                "h1 h2 . p1 p2" max-content
                / max-content minmax(100px, max-content) minmax(1rem, 5rem) max-content minmax(100px, max-content);
        }

        @include bp-up(361px) {
            @include generate-grid-classes((a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p));
        }

        @include bp-between(361px, $bp-sm) {
            grid-template:
                "a1 a2" max-content
                "b1 b2" max-content
                "c1 c2" max-content
                "d1 d2" max-content
                "e1 e2" max-content
                "f1 f2" max-content
                "g1 g2" max-content
                "h1 h2" max-content
                "i1 i2" max-content
                "j1 j2" max-content
                "k1 k2" max-content
                "l1 l2" max-content
                "m1 m2" max-content
                "n1 n2" max-content
                "o1 o2" max-content
                "p1 p2" max-content
                / max-content minmax(100px, max-content);
        }

        @include bp-down(360px) {
            grid-auto-rows: auto;
            grid-template-columns: 100%;
            grid-auto-flow: row dense;
        }

        > * {
            @include fluid-size(4px, 16px, padding-left);
            @include fluid-size(4px, 16px, padding-right);
            padding-top: .25rem;
            padding-bottom: .25rem;
        }
    }

    .phone-grid {
        display: grid;
        grid-template-columns: max-content auto;
        grid-auto-flow: row dense;
        grid-auto-rows: auto;
    }
</style>
