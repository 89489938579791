<template>
    <Card color="teal" title="Customers">
        <p class="-mt-4 mb-4">{{ page_label }}</p>
        <div v-if="validate_msg != ''" class="bg-green-100 p-2">
            <p class="text-green-800 my-0">{{validate_msg}}</p>
        </div>

        <div class="loader" v-if="loading"></div>

        <form class="d-flex flex-wrap align-items-center justify-content-between" @submit.prevent="formSubmit" enctype="multipart/form-data" v-show="$page.props.user.role_type == 'AD' || $page.props.user.role_type == 'EM' || $page.props.user.role_type == 'BR'">
            <div class="flex flex-wrap align-stretch" v-if="!create_new">
                <JetInput class="flex-grow-0" style="width: 200px;" type="text" v-model="form.entity_name" placeholder="Company Name" maxlength="200"/>
                <JetInput class="flex-grow-0" style="width: 200px;" type="text" v-model="form.entity_address" placeholder="Company Address"  maxlength="200"/>
                <JetInput class="flex-grow-0" style="width: 200px;" type="text" v-model="form.entity_city" placeholder="Company City"  maxlength="200"/>
                <JetInput class="flex-grow-0" style="width: 200px;" type="text" v-model="form.entity_state" placeholder="Company State"  maxlength="2"/>
                <JetInput class="flex-grow-0" style="width: 200px;" type="number" v-model="form.entity_zip" placeholder="Company Zip"  maxlength="5"/>
                <JetInput class="flex-grow-0" style="width: 200px;" type="text" v-model="form.entity_county" placeholder="Company County"  maxlength="200"/>
            </div>
            &nbsp;
            <div class="flex flex-wrap align-stretch" v-if="!create_new">
                <button class="btn-blue flex-grow-0 rounded-l-none ml-2" :disabled="disableSubmit">Submit</button>
                <button v-if="show_create_new_button && !loading" class="btn-green flex-grow-0 rounded-l-none ml-2" @click.prevent="openCreateCustomerModal()">Create New</button>
                <button @click.prevent="reset()" class="btn-outline-gray flex-grow-0 rounded-l-none ml-2">Reset</button>
            </div>

        <AccountClearanceSearchTable
            v-if="search_data.length && !create_new"
            class="mt-8"
            :data="search_data"
            :searchable-data="['company_name', 'address_line_1', 'city', 'state', 'zip', 'county']"
            @selected-entity="selectEntity($event)"
            search-placeholder="Search by Company and/or Address..."
        />
        <p v-if="!search_data.length && form.entity_name && !loading && !create_new" class="mt-4 bg-gray-200 p-4">No customers found</p>

        <SimpleModal :visible="showCreateCustomerModal" @on-close="closeCreateCustomerModal()" :width="'800px'">
            <div v-if="validate_msg != ''">
                <p class="text-red-500 my-0"><strong> {{validate_msg}}</strong></p>
            </div>
            <form ref="form" @submit.prevent="submitCreateCustomerForm" enctype="multipart/form-data">
                <h5 class="text-blue-500 mb-3">Create New Customer </h5>
                
                <div class="form-group grid grid-cols-2 gap-4">
                    <div class="mt-4">
                        <JetLabel for="name" value="Name" />
                        <JetInput id="name" type="text" class="mt-1 block w-full" v-model="create_new_customer_form.Org_Entity_Name" required />
                    </div>

                    <div class="mt-4">
                        <SimpleSelect label="Entity Type" name="address_type" v-model="create_new_customer_form.Addresses.Address.Address_Type" required :options="address_type_list" />
                        <!--<div v-if="$page.errors.Mobile" class="text-red-500">{{ $page.errors.Mobile[0] }}</div>-->
                    </div>
                </div>
                <div class="form-group grid grid-cols-2 gap-4">
                    <div class="mt-4">
                        <JetLabel for="address_1" value="Address 1" />
                        <JetInput id="address_1" type="text" class="mt-1 block w-full" v-model="create_new_customer_form.Addresses.Address.Address_Line_1" required />
                    </div>

                    <div class="mt-4">
                        <JetLabel for="address_2" value="Address 2" />
                        <JetInput id="address_2" type="text" class="mt-1 block w-full" v-model="create_new_customer_form.Addresses.Address.Address_Line_2" />
                    </div>
                </div>

                <div class="form-group grid grid-cols-3 gap-4">
                    <div class="mt-4">
                        <JetLabel for="city" value="City" />
                        <JetInput id="city" type="text" class="mt-1 block w-full" v-model="create_new_customer_form.Addresses.Address.City" required />
                    </div>

                    <div class="mt-4">
                        <SimpleSelect label="State" name="state" v-model="create_new_customer_form.Addresses.Address.State" required :options="state_list" />
                    </div>

                    <div class="mt-4">
                        <JetLabel for="zip" value="Zip" />
                        <JetInput id="zip" type="text" class="mt-1 block w-full" v-model="create_new_customer_form.Addresses.Address.Zip" maxlength="5" required />
                    </div>
                </div>

                <div class="form-group grid grid-cols-3 gap-4">
                    <div class="mt-4">
                        <JetLabel for="county" value="County" />
                        <JetInput id="county" type="text" class="mt-1 block w-full" v-model="create_new_customer_form.Addresses.Address.County" required />
                    </div>

                    <div class="mt-4">
                        <JetLabel for="phone" value="Phone" />
                        <JetInput id="phone" type="text" class="mt-1 block w-full" v-model="create_new_customer_form.Phones.Phone.Phone_Num" maxlength="11" required />
                    </div>

                    <div class="mt-4">
                        <JetLabel for="email" value="Email" />
                        <JetInput id="email" type="email" class="mt-1 block w-full" v-model="create_new_customer_form.Email_Address" required />
                        <!-- <div v-if="$page.errors.Email" class="text-red-500">{{ $page.errors.Email[0] }}</div> -->
                    </div>
                </div>

                <div class="flex mt-3 -mx-1 -mb-1">
                    <button
                        type="submit"
                        class="btn-green m-1"
                    >Create New Customer</button>

                    <button
                        type="button"
                        class="btn-outline-gray m-1"
                        @click.prevent="closeCreateCustomerModal()"
                    >Cancel</button>

                    <button
                        type="button"
                        class="btn-outline-gray m-1"
                        @click.prevent="resetCreateCustomerForm()"
                    >Reset</button>
                </div>
            </form>
        </SimpleModal>
    </form>
    </Card>
</template>

<script>
    import AccountClearanceSearchTable from '../Tables/AccountClearanceSearchTable.vue'
    import SimpleSelect from '@/Components/FormElements/SimpleSelect.vue'
    import SimpleModal from '@/Components/SimpleModal.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import Card from '@/Components/Card.vue'
    import moment from 'moment'

    export default {
        components: {
            AccountClearanceSearchTable,
            SimpleSelect,
            SimpleModal,
            JetLabel,
            JetInput,
            Card,
            moment,
        },
        props: {
            entityId: [String]
        },
        emits: ['selected-entity'],
        data() {
            return {
                form: {
                    entity_name: '',
                    entity_address: '',
                    entity_city: '',
                    entity_state: '',
                    entity_zip: '',
                    entity_county: '',
                    entity_limit: 1000,
                },
                loading: false,
                search_data: [],
                show_create_new_button: true,  // Set to false
                page_label: 'Search for a VGM Insurance Customer for Account Clearance.',
                create_new: false,
                address_type_list: [
                    { name: '', value: '' },
                    // { name: 'Billing', value: 'BL' }, -- Commented out 2/20/2024 per Terry Johnson
                    { name: 'Business', value: 'BS' },
                    { name: 'Home', value: 'HM' },
                    // { name: 'Mailing', value: 'ML' }, -- Commented out 2/20/2024 per Terry Johnson
                    // { name: 'Remit To', value: 'RM' }, -- Commented out 2/20/2024 per Terry Johnson
                ],
                state_list: [
                    { name: '', value: '' },
                    {name:"AK",value:"AK"},
                    {name:"AL",value:"AL"},
                    {name:"AR",value:"AR"},
                    {name:"AZ",value:"AZ"},
                    {name:"CA",value:"CA"},
                    {name:"CO",value:"CO"},
                    {name:"CT",value:"CT"},
                    {name:"DE",value:"DE"},
                    {name:"FL",value:"FL"},
                    {name:"GA",value:"GA"},
                    {name:"HI",value:"HI"},
                    {name:"IA",value:"IA"},
                    {name:"ID",value:"ID"},
                    {name:"IL",value:"IL"},
                    {name:"IN",value:"IN"},
                    {name:"KS",value:"KS"},
                    {name:"KY",value:"KY"},
                    {name:"LA",value:"LA"},
                    {name:"MA",value:"MA"},
                    {name:"MD",value:"MD"},
                    {name:"ME",value:"ME"},
                    {name:"MI",value:"MI"},
                    {name:"MN",value:"MN"},
                    {name:"MO",value:"MO"},
                    {name:"MS",value:"MS"},
                    {name:"MT",value:"MT"},
                    {name:"NC",value:"NC"},
                    {name:"ND",value:"ND"},
                    {name:"NE",value:"NE"},
                    {name:"NH",value:"NH"},
                    {name:"NJ",value:"NJ"},
                    {name:"NM",value:"NM"},
                    {name:"NV",value:"NV"},
                    {name:"NY",value:"NY"},
                    {name:"OH",value:"OH"},
                    {name:"OK",value:"OK"},
                    {name:"OR",value:"OR"},
                    {name:"PA",value:"PA"},
                    {name:"RI",value:"RI"},
                    {name:"SC",value:"SC"},
                    {name:"SD",value:"SD"},
                    {name:"TN",value:"TN"},
                    {name:"TX",value:"TX"},
                    {name:"UT",value:"UT"},
                    {name:"VA",value:"VA"},
                    {name:"VT",value:"VT"},
                    {name:"WA",value:"WA"},
                    {name:"WI",value:"WI"},
                    {name:"WV",value:"WV"},
                    {name:"WY",value:"WY"},
                ],
                create_new_customer_form: {
                    Entity_Type: 'O',
                    Org_Entity_Name: '',
                    Entity_Quick_Search: '',
                    Email_Address_Type: '',
                    Email_Address: '',
                    Corespond_Method: 'E', //Email
                    //agent: '',
                    Consumer_Role: "",
                    Addresses: {
                        Address: {
                            Address_Type: '',
                            Address_Line_1: '',
                            Address_Line_2: '',
                            City: '',
                            State: '', // 2 character state code
                            Zip: '', // 5 digit zip only
                            County: '',
                            Country: 'USA',
                            Dflt_Address_Ind: 'Y',
                        }
                    },
                    Phones: {
                        Phone: {
                            Phone_Type: '', // Business Phone
                            Phone_Num: '',
                            Dflt_Phone_Ind: 'Y',
                        }
                    },
                },
                showCreateCustomerModal: false,
                quickInviteForm: {
                    first_name: null,
                    email: null,
                    role_type: null,
                    last_name: null,
                },
                validate_msg: '',
                error_msg: '',

            };
        },
        mounted() {
            if (this.entityId) {
                this.form.entity_name = this.entityId;
                this.formSubmit();
            }
        },
        computed: {
            disableSubmit() {
                if (this.form.entity_name == ''
                && this.form.entity_address == ''
                && this.form.entity_city == ''
                && this.form.entity_state == ''
                && this.form.entity_zip == ''
                && this.form.entity_county == '')
                    return true;
                return false;
            }
        },
        methods: {
            formSubmit() {
                this.loading = true,

                axios.post('/policies/accountclearance_search', this.form)
                    .then( response =>  {
                        this.loading = false,
                        this.search_data = [];
                        this.show_create_new_button = true;

                        // Loop through array result
                        response.data.search_results.forEach(result => {
                            // Freeze (make readonly) every object in the array (this boosts Performance A LOT)
                            this.search_data.push(Object.freeze(result));
                        });
                })
            },
            selectEntity(entity) {
                this.$emit('selected-entity', entity);
            },
            createNew(){
                // Maybe do it as an inline form to prevent direct access
                // window.location.href = "some location";
                this.page_label = 'Create New VGM Insurance Customer';
                this.create_new = true;
            },
            openCreateCustomerModal() {
                this.showCreateCustomerModal = true;
            },
            closeCreateCustomerModal() {
                this.resetCreateCustomerForm();
                this.showCreateCustomerModal = false;
            },
            resetCreateCustomerForm() {
                // In order to keep data reactive you need to set individual properties
                // Straight up replacing an entire object will make the properties no longer reactive and break any current reactivity
                this.create_new_customer_form.Org_Entity_Name = '';
                this.create_new_customer_form.Entity_Quick_Search = '';
                this.create_new_customer_form.Entity_Type = 'O';
                this.create_new_customer_form.Addresses.Address.Address_Line_1 = '';
                this.create_new_customer_form.Addresses.Address.Address_Line_2 = '';
                this.create_new_customer_form.Addresses.Address.City = '';
                this.create_new_customer_form.Addresses.Address.State = '';
                this.create_new_customer_form.Addresses.Address.Zip = '';
                this.create_new_customer_form.Addresses.Address.County = '';
                this.create_new_customer_form.Phones.Phone.Phone_Num = '';
                this.create_new_customer_form.Email_Address = '';
            },
            submitCreateCustomerForm(e) {
                //this.create_new_customer_form.agent = this.$page.props.user.email;
                let vm = this;
                vm.create_new_customer_form.Entity_Quick_Search = vm.create_new_customer_form.Org_Entity_Name;
                vm.create_new_customer_form.Phones.Phone.Phone_Type = vm.create_new_customer_form.Addresses.Address.Address_Type,
                vm.create_new_customer_form.Email_Address_Type = vm.create_new_customer_form.Addresses.Address.Address_Type;
                vm.loading = true;
                axios.post('/policies/submitCreateCustomerForm', vm.create_new_customer_form)
                    .then( response =>  {
                        vm.loading = false;
                        vm.validate_msg = response.data.message;
                        vm.closeCreateCustomerModal();
                        // Show success message
                        })
                            .catch(function (error) {
                            vm.loading = false;
                            vm.validate_msg = error.response.data.errors.email[0]
                            alert(error.response);
                        });


                // After success
                //vm.closeCreateCustomerModal();
            },
            reset() {
                this.form.entity_name = '';
                this.form.entity_address = '';
                this.form.entity_city = '';
                this.form.entity_state = '';
                this.form.entity_zip = '';
                this.form.entity_county = '';
            },
        }
    }
</script>
